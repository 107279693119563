import { Component } from 'vue-property-decorator'
import throttle from 'lodash/throttle'

import { Inject } from '../../../support'
import { ISiteService, SiteServiceType } from '../../../contexts'

import { DynamicContentAction } from '../../analytics/events/dynamicContent.event'

import { DynamicContentData } from '../contracts/dynamicContent'
import { DynamicContentMixin } from './dynamicContent.mixin'
import {
  PRODUCT_RECOMMENDATIONS_KEY,
  ProductsRecommendationsData,
  RecommendationSourcePayload, SuggestedProductsSource
} from '../contracts/suggestedProducts'

/**
 * Mixin capable of handling dynamic content event helper functions
 * @see DynamicContent
 * @see DynamicContentMixin
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<DynamicHelperMixin>({
  name: 'DynamicHelperMixin',
  beforeDestroy (): void {
    if (this.scrollEventTriggered) {
      window.removeEventListener('scroll', this.onScroll)
    }
  }
})
export class DynamicHelperMixin extends DynamicContentMixin {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public dynamicContentData: DynamicContentData | null = null
  public scrollEventTriggered: boolean = false
  public ref: HTMLElement | null = null

  /**
   * Composes a content for dynamic data events
   * @see DynamicContent
   */
  public composeDynamicContentData (title: string, source?: string | null, recommendationIds?: DynamicContentData['recommendationIds'] | null): void {
    if (!source) {
      if (recommendationIds) {
        this.dynamicContentData = {
          recommendationIds: recommendationIds,
          title: title
        }
      }
    } else {
      const productRecommendations = this.siteService.getProperty(PRODUCT_RECOMMENDATIONS_KEY) as ProductsRecommendationsData

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const suggestionsData = productRecommendations.automation[source] as RecommendationSourcePayload

      if (suggestionsData.type === SuggestedProductsSource.Synerise) {
        this.dynamicContentData = {
          recommendationIds: suggestionsData.value ?? '',
          title: title
        }
      }
    }
  }

  /**
   * Handles show event (when user scrolls to section with products)
   * @see DynamicContent
   */
  public handleContentShow (ref: HTMLElement | null, onScroll: boolean = true): void {
    if (!ref) {
      return
    }

    this.ref = ref

    if (onScroll) {
      window.addEventListener('scroll', throttle(this.onScroll, 1000))
    } else {
      if (this.dynamicContentData) {
        this.triggerDynamicContentEvent(this.dynamicContentData, DynamicContentAction.Show)
      }

      this.scrollEventTriggered = true
    }
  }

  public onScroll () {
    if (!this.ref) {
      return
    }

    if (this.ref.getBoundingClientRect().y < window.innerHeight) {
      if (!this.scrollEventTriggered && this.dynamicContentData) {
        this.triggerDynamicContentEvent(this.dynamicContentData, DynamicContentAction.Show)
      }

      this.scrollEventTriggered = true
    }
  }
}
