import { SyneriseUid } from '../../../modules/ProductsTeaser/ProductsTeaser.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum DynamicContentSource {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface DynamicContentData {
  recommendationIds: SyneriseUid | string
  siteUrl?: string
  title: string
}
