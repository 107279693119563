
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum RecommendationSourceModes {
  // AddToCart = 'addToCart', !DEPRECATED
  Brand = 'brand',
  BrandAndCategory = 'brandAndCategory',
  Recommendation = 'recommendation',
  Synerise = 'synerise'
}
