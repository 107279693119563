import { Component, Inject as VueInject, Vue } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../support'
import { ISiteService, SiteServiceType } from '../../../contexts'

import { DynamicContentAction, DynamicContentLabel } from '../../analytics/events/dynamicContent.event'

import { DynamicContentData, DynamicContentSource } from '../contracts/dynamicContent'

/**
 * Mixin capable of handling dynamic content event
 * @see DynamicContent
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'DynamicContentMixin'
})
export class DynamicContentMixin extends Vue {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public triggerDynamicContentEvent (dynamicData: DynamicContentData, action: string): void {
    const isPremium = this.$store.getters['auth/isUserPremium']
    const path = this.$route.fullPath
    const page = document.head.querySelector('title')
    const isMobile = this.isMobile()
    const site = this.siteService.getActiveSite().address

    let recoId: string = ''
    switch (isPremium) {
      case true:
        if (typeof dynamicData.recommendationIds === 'object') {
          recoId = dynamicData.recommendationIds.premium ?? ''
        } else {
          recoId = ''
        }
        break
      case false:
        if (typeof dynamicData.recommendationIds === 'object') {
          recoId = dynamicData.recommendationIds.generic ?? ''
        } else {
          recoId = ''
        }
        break
    }

    let url: string = ''
    if (path === '/') {
      url = `${site}?snrai_campaign=${recoId}`
    } else if (path.includes('?') && recoId) {
      url = `${site}${path}&snrai_campaign=${recoId}`
    } else {
      url = `${site}${path}?snrai_campaign=${recoId}`
    }

    let label: string = ''
    switch (action) {
      case DynamicContentAction.Click:
        label = DynamicContentLabel.Click
        break
      case DynamicContentAction.Show:
        label = DynamicContentLabel.Show
    }

    this.eventBus.emit('app:dynamicContent', {
      action: action,
      eventUUID: '',
      unique: null,
      createDate: Date.now(),
      label: label,
      title: dynamicData.title ? `${dynamicData.title} | ${page ? (path !== '/' ? page.textContent : 'Strona główna') : ''}` : '',
      url: url,
      recoId: recoId,
      source: `WEB_${isMobile ? DynamicContentSource.Mobile : DynamicContentSource.Desktop}`
    })
  }
}
